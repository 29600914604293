import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from 'components/Link'

import s from './Logo.module.css'

function Logo({ businessUnit, colour, className, href }) {
  const content = (businessUnit === 'modellink') ?
    (
    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1134.29 232.61" fill={colour}>
      <path d="M1001.46,11.89h23.64c.2,1.01,.48,1.9,.54,2.81,.09,1.43,.03,2.88,.03,4.32,0,35.53,0,71.05,0,106.58,0,1.72,.07,3.44,.12,5.29,1.8-.03,2.49-1.25,3.34-2.07,11.68-11.16,23.33-22.36,34.99-33.54,8.54-8.19,17.15-16.32,25.6-24.61,2.12-2.08,4.3-2.91,7.24-2.84,7.67,.17,15.35,.05,23.03,.07,1.24,0,2.49,.14,3.95,.22-.09,1.72-1.32,2.32-2.17,3.1-15.78,14.51-31.58,28.99-47.36,43.5-2.46,2.26-4.88,4.57-7.46,6.99,20.73,31.04,41.31,61.87,61.76,92.49-.47,1.44-1.38,1.23-2.11,1.24-8.64,.02-17.27,.02-25.91,0-.63,0-1.26-.13-2.23-.23-16.56-25.26-33.2-50.65-49.94-76.17-3.27,1.71-5.35,4.4-7.84,6.57-2.53,2.2-4.94,4.56-7.4,6.84-2.46,2.28-4.91,4.56-7.61,7.06v5.13c0,15.04,0,30.09-.01,45.13,0,1.72,.24,3.49-.35,5.09-3.61,.74-18.56,.87-23.77,.21-.15-.83-.38-1.73-.45-2.65-.08-1.11-.02-2.24-.03-3.36,0-63.69,0-127.38,0-191.07,0-1.89-.19-3.8,.39-6.07Z"/>
      <path d="M963.13,214.72c-3.59,.86-18.34,1.02-23.61,.32-1.14-1.55-.65-3.37-.65-5.07-.04-14.56-.02-29.12-.02-43.67,0-16,.02-32-.02-47.99,0-3.83,.02-7.66-.94-11.44-2.86-11.27-10.11-17.99-21.37-20.06-7.61-1.4-15.29-.88-22.77,1.36-10.73,3.21-18.43,9.99-23.24,20.03-3.36,7.03-4.87,14.53-4.9,22.3-.05,12.96-.02,25.92-.02,38.88,0,13.6,0,27.2,0,40.8,0,1.56-.13,3.13-.19,4.6-3.75,.81-18.62,.94-23.59,.27-.98-1.24-.66-2.73-.66-4.13-.03-7.36-.02-14.72-.02-22.08,0-38.08,0-76.15,0-114.23,0-1.9,.08-3.8,.13-5.93,3.94-1.1,7.76-.52,11.5-.54,3.63-.01,7.3-.49,11.19,.46,.63,7.02-.15,14.03,.43,20.85,1.13,.43,1.41-.25,1.76-.73,1.22-1.68,2.34-3.44,3.63-5.07,8.11-10.28,18.76-16.15,31.62-18.18,9.42-1.49,18.81-1.28,28.09,.91,17.45,4.11,28.13,15.01,31.88,32.55,1.31,6.1,1.96,12.32,1.97,18.58,.02,31.04,.02,62.07,0,93.11,0,1.41-.13,2.82-.19,4.12Z"/>
      <path d="M191.88,11.91h8.52c1.17,1.4,.84,2.9,.85,4.3,.03,9.12,.02,18.24,.02,27.36,0,55.04,0,110.08-.01,165.12,0,2.06,.3,4.15-.45,6.35-.74,.13-1.5,.32-2.26,.36-.78,.04-1.57-.06-2.65-.11-.2-.99-.47-1.87-.53-2.76-.09-1.43-.03-2.88-.03-4.32,0-60.48,0-120.96-.02-181.44,0-1.85,.32-3.75-.36-5.5-.38-.03-.56-.1-.69-.05-.12,.05-.21,.21-.3,.34-.17,.27-.36,.53-.49,.82-28.22,64.06-56.43,128.12-84.65,192.18-.06,.13-.23,.22-.6,.57-1.73,.61-3.76,.19-6.03,.19-9.68-21.7-19.1-43.37-28.63-65-9.53-21.65-18.98-43.33-28.55-64.97-9.55-21.59-18.91-43.27-28.53-64.72-.51,.07-.71,.04-.81,.12-.1,.09-.12,.29-.14,.45-.04,.32-.08,.63-.08,.95-.05,64-.09,127.99-.14,191.99,0,.14-.11,.29-.29,.74-1.42,.84-3.1,.53-5.01,.23-.14-.97-.32-1.89-.38-2.81-.07-1.12-.02-2.24-.02-3.36,0-63.68,0-127.36,0-191.04,0-1.89-.19-3.8,.37-5.94,2.92-.46,5.75-.23,8.55-.16,1.49,1.26,1.89,3.04,2.58,4.61,21.14,48.14,42.26,96.3,63.39,144.45,6.23,14.2,12.46,28.39,18.7,42.59,.57,1.3,1.23,2.57,1.85,3.86,.29-.16,.43-.23,.56-.32,.13-.09,.26-.19,.35-.31,.19-.26,.37-.52,.51-.8,.21-.43,.38-.88,.57-1.32,28.09-63.95,56.17-127.89,84.26-191.84,.06-.14,.17-.27,.54-.84Z"/>
      <path d="M716.2,12.12c6.24-.68,20.56-.52,24.15,.23,.09,.52,.3,1.13,.3,1.73,.01,66.82,.01,133.64,.01,200.59-.78,.29-1.34,.67-1.91,.67-7.03,.03-14.06,.01-21.09-.02-.3,0-.59-.2-1.15-.4-.13-.78-.35-1.68-.41-2.6-.08-1.11-.02-2.24-.02-3.36,0-63.64,0-127.29,0-190.93,0-1.89,.07-3.78,.11-5.94Z"/>
      <path d="M803.55,215.02c-4.99,.65-20.09,.55-23.56-.13-.1-.12-.22-.24-.3-.37-.07-.13-.14-.29-.15-.44-.05-1.6-.13-3.19-.13-4.79,.02-46.55,.05-93.1,.1-139.65,0-.29,.21-.58,.45-1.2,.54-.13,1.27-.42,2.02-.46,8.26-.44,18.24-.16,21.66,.62,.11,.68,.32,1.44,.35,2.2,.54,13.61,.18,139.77-.44,144.21Z"/>
      <path d="M493.62,106.66c.16-4.46,.05-8.92,.07-13.38,.02-4.48,0-8.96,0-13.44s0-8.96,0-13.44c0-4.64,0-9.28,0-13.92,0-4.48,0-8.96,0-13.44,0-4.48-.03-8.96,0-13.44,.04-4.45-.18-8.92,.18-13.59h5.23c.17,.91,.44,1.8,.48,2.7,.08,1.76,.03,3.52,.03,5.28,0,62.89,0,125.78,0,188.67,0,2.06-.06,4.12-.1,6.15-2,.88-3.66,.81-5.47,.16-.75-4.16-.26-8.32-.34-12.45-.09-4.32-.01-8.64-.02-12.96-.01-4.14-.05-8.28-.07-12.49-1.82,.25-1.69,1.65-2.03,2.54-2.45,6.5-5.79,12.47-10.21,17.81-10.55,12.74-24.07,19.69-40.57,20.91-7.39,.55-14.71,.28-21.89-1.58-12.34-3.2-22.4-9.95-30.33-19.9-6.8-8.53-11.14-18.27-13.55-28.85-4.7-20.59-4.08-40.95,3.19-60.92,3.37-9.27,8.25-17.68,15.24-24.72,10.86-10.95,23.95-16.73,39.47-16.91,7.24-.09,14.38,.45,21.34,2.53,17.42,5.2,29.11,16.68,36.16,33.2,.79,1.86,1.48,3.77,2.21,5.65l.99-.14Zm.37,34.99c-.55-6.04-.88-12.11-1.7-18.12-1.03-7.47-3.13-14.68-6.52-21.46-7.07-14.14-17.96-23.87-33.21-28.42-6.16-1.84-12.49-2.48-18.92-2.45-13.79,.07-25.54,4.96-35.36,14.58-7.23,7.09-12.11,15.65-15.27,25.17-5.98,18.03-6.63,36.34-2.39,54.83,2.06,8.95,5.47,17.34,10.64,24.98,4.56,6.74,10.37,12.1,17.46,16.09,6.68,3.76,13.9,5.54,21.48,6.05,11.94,.81,23.19-1.38,33.46-7.8,10.66-6.67,18.04-16.09,23.03-27.49,5.02-11.45,7.1-23.49,7.3-35.97Z"/>
      <path d="M651.68,143.25h-80.92c-6.72,0-13.43-.04-20.14,.01-6.67,.05-13.36-.24-19.86,.19-.3,.58-.56,.85-.55,1.12,.24,9.95,1.49,19.76,4.37,29.31,1.63,5.38,3.83,10.51,6.81,15.28,8.42,13.5,20.67,21.13,36.32,23.11,9.4,1.19,18.82,.95,28.08-1.5,19.27-5.09,31.63-17.25,37.14-36.38,.88-3.07,1.5-6.21,2.23-9.29,2.37-.68,2.37-.68,5.37-.16,0,.86,.13,1.79-.02,2.68-1.03,6-2.55,11.88-5.13,17.43-7.49,16.14-20.21,26.2-37.08,30.86-13.31,3.68-26.9,3.68-40.31,.29-12.23-3.09-22.02-9.98-29.36-20.28-5.85-8.2-9.44-17.4-11.57-27.17-2.95-13.55-3.78-27.24-1.91-41.01,1.78-13.04,6.25-25.1,13.82-35.91,10.26-14.65,24.25-23.67,41.99-25.87,17.86-2.22,34.34,1.65,48.33,13.71,8.19,7.06,13.81,15.88,17.59,25.92,4.28,11.36,6.07,23.16,5.72,35.28-.01,.48-.11,.95-.2,1.42-.03,.14-.17,.25-.71,.97Zm-5.09-6.31c.54-4.14-.2-7.94-.69-11.72-1.31-10.09-4.29-19.65-9.62-28.39-6.38-10.46-15.26-17.98-26.76-22.24-6.81-2.52-13.91-3.49-21.17-3.36-16.6,.3-30.39,6.6-41.16,19.28-7.28,8.57-11.85,18.53-14.52,29.39-1.37,5.57-2.37,11.22-2.28,17.23,1.04,.14,1.95,.34,2.87,.36,26.67,.68,108.47,.31,113.35-.56Z"/>
      <path d="M356.63,142.22c-.22,12.66-2.42,24.86-7.34,36.49-6.05,14.3-15.58,25.57-29.4,32.97-7.15,3.83-14.77,5.93-22.84,6.57-7.86,.63-15.65,.36-23.31-1.58-13.33-3.38-24.11-10.76-32.65-21.45-6.9-8.63-11.36-18.48-13.88-29.2-4.61-19.63-3.9-39,3.16-58,3.68-9.89,9.22-18.61,16.91-25.85,11.67-10.98,25.49-16.58,41.6-16.83,23.99-.38,42.1,9.94,55.03,29.8,6.11,9.38,9.51,19.86,11.15,30.87,.8,5.36,1.07,10.8,1.57,16.2Zm-126.38,.28c-.26,9.82,1.5,19.22,4.06,27.47,2.34,7.55,5.76,14.58,10.63,20.83,10.25,13.17,23.54,20.8,40.36,22.07,15.7,1.19,29.77-2.7,41.87-12.99,7.97-6.78,13.68-15.2,17.39-24.92,6.81-17.86,7.69-36.13,3.34-54.69-2.18-9.29-5.83-17.94-11.54-25.62-8.2-11.02-18.75-18.63-32.23-21.92-6.28-1.53-12.66-1.83-19.06-1.33-9.75,.76-18.71,3.87-26.77,9.52-9.56,6.7-16.52,15.5-21.15,26.14-4.85,11.15-7.08,22.86-6.92,35.44Z"/>
      <path d="M681.07,214.8c-.75,.27-1.18,.53-1.62,.56-1.1,.06-2.21,.02-3.57,.02-.19-1.23-.48-2.29-.49-3.35-.05-5.28-.03-10.55-.03-15.83,0-59.01,0-118.02,0-177.03,0-2.22,0-4.44,0-6.67,1.92-1.07,3.58-.78,5.48-.4,.13,.98,.3,1.89,.37,2.81,.06,.96,.02,1.92,.02,2.88,0,63.97,0,127.93,0,191.9,0,1.73-.1,3.45-.15,5.12Z"/>
      <path d="M779.63,12.36c3.72-.76,17.7-.92,23.77-.32,.17,.5,.55,1.07,.55,1.65,.02,8.77,0,17.54-.04,26.31,0,.29-.2,.58-.44,1.23-2.4,.83-5.12,.29-7.75,.38-2.55,.09-5.1,.04-7.65,.01-2.68-.03-5.38,.15-8.25-.22-.74-3.33-.31-6.51-.38-9.65-.07-3.35-.05-6.7,0-10.05,.04-3.15-.21-6.32,.21-9.36Z"/>
    </svg>
    )
    :
    (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1116.9 285.8"
      fill={colour}
    >
      <path d="M194,184.8C176.7,197,150.9,203,116.5,203,45.4,203,6.4,185.6,0,150.9L77,143c.3,3.5,4.5,7.6,12.6,11.9a50.2,50.2,0,0,0,26,6.8c21,0,35.6-7,35.6-18.7,0-9.5-8.7-15.5-25.8-18.5l-73-12.7c-30-5.2-44.8-23.6-44.8-55.1C7.6,38,16.8,23.6,35,14.1S76.4,0,104.7,0c36.7,0,62.7,4.3,78.7,12.8Q214,29,216.4,51.3l-72.8,4.1c-4.2-9.5-15.4-16.9-33.3-16.9-22.1,0-32.7,8.4-32.7,17.1s5,12.8,15.1,14.4l77,13c33.6,5.7,50.1,20.9,50.1,55.9C219.8,157.6,211.1,172.8,194,184.8Z" />
      <path d="M299,135.4c1.4,15.5,16.5,29.6,42,29.6,20.5,0,29.7-3.8,38.4-14.7l67.5,8.2c-14.9,24.7-51.6,43.9-106.7,43.9-31.4,0-57.1-7-77.3-20.9s-30.5-33.1-30.5-57.8q0-34.2,30.2-53.7c20.2-13,46.5-19.5,79.3-19.5,70.2,0,111.1,29.8,112.5,84.9Zm84.6-26.9q-4.7-25.2-42-25.2-35.7,0-42.9,25.2Z" />
      <path className="cls-1" d="M535.6,196.4H471.2V6.5h64.4Z" />
      <path d="M616.3,135.4c1.4,15.5,16.5,29.6,42,29.6,20.4,0,29.6-3.8,38.3-14.7l67.5,8.2c-14.8,24.7-51.5,43.9-106.7,43.9q-46.9,0-77.3-20.9c-20.4-13.8-30.5-33.1-30.5-57.8q0-34.2,30.3-53.7c20.1-13,46.4-19.5,79.2-19.5,70.3,0,111.2,29.8,112.6,84.9Zm84.5-26.9q-4.6-25.2-42-25.2-35.7,0-42.8,25.2Z" />
      <path d="M999.3,151.1C980,185.3,942.4,202.4,887,202.4c-53.8,0-106.9-24.1-106.9-78.4,0-51.3,50.6-73.5,106.9-73.5,58.5,0,94.9,13.8,110.6,49.4l-65.8,6.5c-9-14.4-19.3-21.2-44-21.2a42.8,42.8,0,0,0-29.4,11.1c-8.1,7.3-12.3,16.3-12.3,26.9,0,25.8,15.7,42,42.6,42,12,0,21-1.6,26.6-5.1s11.7-9.5,18.2-18.7Z" />
      <path d="M1116.9,96.1h-25.2v52.6c0,12.7,3.6,13.8,18.5,13.8h6.1v35.6a303.9,303.9,0,0,1-35,1.6c-24.3,0-35.8-1.4-43.7-9.2s-8.9-16.8-8.9-38.3V96.1h-18.8V59.2h18.8V14.7h63V59.2h25.2Z" />
      <path d="M75.6,284.7H67.7v-9.1c0-7,.1-12.1.2-15.6h-.1c-.9,2.2-2.3,5.4-4.4,9.4l-7.6,15.3H49.6l-6.9-13.5A108,108,0,0,1,37.5,260h-.1c.1,4.5.2,9.6.2,15.4v9.3H29.7V248.9H39l8.3,15.8A95,95,0,0,1,52.7,276h.1c.6-1.7,2.4-5.6,5.5-11.7l8-15.4h9.3Z" />
      <path d="M116.5,250.3a27.4,27.4,0,0,1,11.9-2.5c5.7-.1,12.7,1.7,17.7,6,3.2,2.7,5.4,7.4,5.4,12.9,0,14.1-12.7,19.1-23.1,19.1s-22.8-5.3-22.8-19C105.6,258.1,110.5,252.8,116.5,250.3Zm1.4,26.1c2.6,2.3,6.1,3.5,10.5,3.5a16,16,0,0,0,10.7-3.5c2.7-2.3,4-5.4,4-9.6a12.5,12.5,0,0,0-3.9-9.7c-2.7-2.3-6.3-3.5-10.8-3.5s-8.4,1.2-10.8,3.7a13.2,13.2,0,0,0-3.6,9.5A12.4,12.4,0,0,0,117.9,276.4Z" />
      <path d="M197.6,284.7H181.5V248.9h15.4q22.5,0,22.5,17.4C219.4,278.6,212.1,284.7,197.6,284.7Zm-1.5-29.8h-6.7v23.6h7c9.8,0,14.7-4,14.7-12.1S206.1,254.9,196.1,254.9Z" />
      <path d="M285,284.7H249.3V248.9h34.4v6H257.3v8.4H281v5.9H257.3v9.3H285Z" />
      <path className="cls-1" d="M345,284.7H314.9V248.9h8v29.6H345Z" />
      <path d="M463.5,284.7h-7.9v-9.1c0-7,.1-12.1.2-15.6h-.1a84.6,84.6,0,0,1-4.4,9.4l-7.6,15.3h-6.2l-6.9-13.5c-2.3-4.6-4.1-8.3-5.2-11.2h-.1c.1,4.5.3,9.6.3,15.4v9.3h-8V248.9h9.3l8.3,15.8a95,95,0,0,1,5.4,11.3h.1a115.1,115.1,0,0,1,5.6-11.7l8-15.4h9.2Z" />
      <path d="M491.7,284.7l17.6-35.8h8.3l17.9,35.8h-8.9l-4-8.3H504l-4,8.3Zm21.4-28.2-6.3,13.9h12.9l-6.5-13.9Z" />
      <path d="M602,284.7h-7.3l-14.3-15.3c-5-5.4-8-8.7-9-10.1h-.1c.2,2.8.3,8.5.3,17v8.4h-7.9V248.9h7.2l14.4,15.5,8.9,9.8h.1q-.3-5.2-.3-15.3v-10h8Z" />
      <path d="M630.1,284.7l17.6-35.8H656l17.9,35.8h-8.8l-4.1-8.3H642.4l-4,8.3Zm21.4-28.2-6.3,13.9h12.9l-6.5-13.9Z" />
      <path d="M734.7,258.9c-2.8-3.5-7-5.3-12.7-5.3a15.1,15.1,0,0,0-10.2,3.4c-2.7,2.2-4.1,5.5-4.1,9.8a12.5,12.5,0,0,0,4,9.6,15.2,15.2,0,0,0,10.2,3.5q6.1,0,9.9-3c2.4-2,3.7-4,3.8-6.1v-.2h-12v-5.5H743v19.6h-7.3v-4.2h-.1a19,19,0,0,1-13.7,5.2,27.6,27.6,0,0,1-15.5-4.4c-4.3-3-7.1-8.1-7.1-14.5a16.6,16.6,0,0,1,3.2-10.3,19.1,19.1,0,0,1,8.3-6.5,29,29,0,0,1,11.2-2.1c5.8,0,10.9,1.3,14.2,3.3a20.6,20.6,0,0,1,6.8,6Z" />
      <path d="M810.3,284.7H774.6V248.9h34.3v6H782.6v8.4h23.7v5.9H782.6v9.3h27.7Z" />
      <path d="M886.1,284.7h-7.9v-9.1c0-7,.1-12.1.2-15.6h-.1a84.6,84.6,0,0,1-4.4,9.4l-7.6,15.3h-6.2l-6.9-13.5c-2.3-4.6-4.1-8.3-5.2-11.2h-.1c.1,4.5.2,9.6.2,15.4v9.3h-7.9V248.9h9.3l8.3,15.8a95,95,0,0,1,5.4,11.3h.1a115.1,115.1,0,0,1,5.6-11.7l8-15.4h9.2Z" />
      <path d="M953,284.7H917.4V248.9h34.3v6H925.4v8.4H949v5.9H925.4v9.3H953Z" />
      <path d="M1021.3,284.7H1014l-14.3-15.3c-5-5.4-8-8.7-9-10.1h-.1c.2,2.8.3,8.5.3,17v8.4H983V248.9h7.3l14.3,15.5,8.9,9.8h.1q-.3-5.2-.3-15.3v-10h8Z" />
      <path d="M1087.2,255h-14.4v29.7h-8V255h-14.4v-6.1h36.8Z" />
    </svg>
  )

  const setClass = classNames(s.container, className)

  if (!href) return <div className={setClass}>{content}</div>

  return href.startsWith('http') ? (
    <a href={href} className={setClass}>
      {content}
    </a>
  ) : (
    <Link href={href} type="Site" className={setClass}>
      {content}
    </Link>
  )
}

Logo.propTypes = {
  businessUnit: PropTypes.string,
  small: PropTypes.bool,
  colour: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
}
Logo.defaultProps = {
  businessUnit: 'selectmodel',
  small: false,
  colour: '#000',
  className: null,
  href: null,
}

export default Logo
